<template>
  <b-modal
    id="driverSignupPayments"
    no-enforce-focus
    lazy
    hide-footer
    size="lg"
    :title="$t('payments.deliverScheduled.modalTitle')"
    @show="handleOpen"
    @hide="handleClose"
  >
    <driver-header :id="driver.id" :modal="true" />
    <b-card v-if="!this.$apollo.queries.transactions.loading">
      <b-row>
        <b-col md="6">
          <p v-if="driver.booking" class="mb-2">
            {{ $t('payments.deliverScheduled.planTitle') }}:
            <strong>{{ driver.booking.plan.name }}</strong>
          </p>
          <p v-else class="mb-2">
            {{ $t('payments.deliverScheduled.planTitle') }}:
            <strong v-text="$t('payments.deliverScheduled.planNotSelected')" />
          </p>
          <p v-if="driver.booking && driver.booking.driver_scheduled_at" class="mb-2">
            {{ $t('payments.deliverScheduled.planScheduledAt') }}:
            <strong>{{ driver.booking.driver_scheduled_at | format_date }}</strong>
          </p>
        </b-col>
        <b-col md="6">
          <p class="mb-0">
            {{ $t('payments.deliverScheduled.planLastUpdate') }}:
            <strong>{{ driver.updated_at | format_from_now }}</strong>
          </p>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col v-for="transaction in transactions.items" :key="transaction.id" md="6">
          <h5>{{ ['PRE_PAYMENT', 'PRE_PAYMENT_RTO', 'PRE_PAYMENT_PROMOTIONAL'].includes(transaction.kind) ? $t('payments.deliverScheduled.transactionKindPre') : $t('payments.deliverScheduled.transactionKindWeek') }}</h5>
          <p class="mb-2">
            {{ $t('payments.deliverScheduled.transactionType') }}:
            <strong>{{ transaction.payment_method.type | filterPaymentMethodType }}</strong>
          </p>
          <p class="mb-2">
            {{ $t('payments.labels.expireDate') }}:
            <strong>{{ transaction.overdue_limit | format_date }}</strong>
          </p>
          <p class="mb-4">
            {{ $t('payments.deliverScheduled.transactionAmount') }}:
            <strong v-html="setPriceFormat(transaction.amount)" />
          </p>
          <p v-if="transaction.status === 'PAID'" class="mb-2">
            <b-button
              v-b-tooltip
              variant="success"
              disabled
              :title="$t('payments.deliverScheduled.transactionStatusPaidTitle')"
            >
              <i class="fa fa-check" /> {{ $t('payments.deliverScheduled.transactionStatusPaidLabel') }}
            </b-button>
          </p>
          <div v-else>
            <div v-if="transaction.status === 'PENDING' && transaction.manual_confirmation">
              <p class="mb-2">
                <b-button
                  v-b-tooltip
                  variant="warning"
                  :href="`${invoiceURL}${transaction.id}`"
                  target="_blank"
                  :title="$t('payments.deliverScheduled.transactionStatusPendingTitle')"
                >
                  <i class="fa fa-external-link" /> {{ $t('payments.deliverScheduled.transactionStatusPendingLabel') }}
                </b-button>
              </p>
              <p class="mb-2">
                <!-- <upload-image v-model="proofImage[transaction.id]" :value="transaction.proof.url" data-test="modal-transaction__btn-upload-proof" /> -->
                <upload-image
                  v-model="proofImage[transaction.id]"
                  :value="transaction.proof.url"
                  :label="$t('payments.deliverScheduled.proofImageLabel')"
                  file-upload-name="data"
                  file-upload-endpoint="/docs"
                  auto-upload
                  :file-upload-props="{driverId:driver.id, documentType:'proof_of_payment'}"
                  data-test="modal-transaction__btn-upload-proof"
                />
                <modal-show-image
                  v-if="!proofImage[transaction.id]"
                  class="mb-2"
                  :url="transaction.proof.url"
                  :title="$t('payments.deliverScheduled.proofImageModal')"
                  :display="transaction.proof ? 1 : 0"
                />
              </p>
            </div>
            <div v-else>
              <p class="mb-2">
                <b-button
                  v-b-tooltip
                  variant="danger"
                  :href="`${invoiceURL}${transaction.id}`"
                  target="_blank"
                  :title="$t('payments.deliverScheduled.proofImageButtonTitle')"
                >
                  <i class="fa fa-external-link" /> {{ $t('payments.deliverScheduled.proofImageButtonLabel') }}
                </b-button>
              </p>
              <p class="mt-4 ml-2 mr-2">
                <upload-image
                  v-model="proofImage[transaction.id]"
                  :label="$t('payments.deliverScheduled.proofImageLabel')"
                  :help-text="$t('payments.deliverScheduled.proofImageUploadText')"
                  file-upload-name="data"
                  file-upload-endpoint="/docs"
                  auto-upload
                  :file-upload-props="{driverId:driver.id, documentType:'proof_of_payment'}"
                  data-test="modal-transaction__btn-upload-proof"
                />
              </p>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="12" class="text-center">
          <hr>
          <b-button variant="success" v-if="showConfirmPaymentButton" @click="$clickManualPay()">
            {{ $t('payments.deliverScheduled.confirmPayment') }}
          </b-button>

          <b-button
            id="driver-view-cancel-booking"
            class="font-weight-bold"
            variant="danger"
            :disabled="!cancelButtonVisible"
            @click="$_confirmCancelDelivery"
          >
            {{ $i18n.t('modalVehicleDelivery.button.cancelDelivery') }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-else>
      <p class="text-center mb-0 p-5">
        {{ $t('payments.deliverScheduled.loadingPayment') }}....
      </p>
    </b-card>
  </b-modal>
</template>

<style scoped></style>

<script>
import DriverHeader from '@components/drivers/header';
import UploadImage from '@components/shared/upload-image';
import ModalShowImage from '@components/drivers/proof-docs/modal-show-image';
import { isMexico, isBrazil } from '@utils/helper-region'
import { formatPrice } from '@utils/numbers';
import { mapGetters } from 'vuex';


import LIST_TRANSACTIONS from '@graphql/transaction/queries/driver.gql';
import TRANSACTION_PAY from '@graphql/transaction/mutations/pay.gql';
import CANCEL_DELIVERY from '@graphql/schedules/mutations/cancel-delivery.gql';

export default {
  name: 'ModalDriverSignupPayments',
  components: {
    DriverHeader,
    UploadImage,
    ModalShowImage,
  },
  props: {
    driver: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      invoice_url_br: process.env.KOVI_FATURA_URL,
      invoice_url_mx: process.env.KOVI_FATURA_URL_MX,
      window_closed: true,
      transactions: {
        items: [],
      },
      proofImage: {},
      cancel_delivery_mutation: CANCEL_DELIVERY
    };
  },
  computed: {
    ...mapGetters({
      deliveryScheduleId: 'schedule/deliveryScheduleId'
    }),
    ...mapGetters('driver', {
      profile: 'header'
    }),
    driverId() {
      const currentDriverId = this.driver.driver ? this.driver.driver.id : this.driver.id

      return currentDriverId
    },
    showConfirmPaymentButton() {
      const transactions = this.transactions.items;

      if (!transactions.length) {
        return;
      }

      const checkPayment = item => item.status === 'PAID' ? true : item.manual_confirmation;
      const areInvalidTransaction = transactions.map(checkPayment).some(boolean => !boolean);

      return !areInvalidTransaction;
    },
    invoiceURL() {
      if (isBrazil()) {
        return this.invoice_url_br
      } else {
        return this.invoice_url_mx
      }
    },
    driverStep () {
      return this.profile ? this.profile.step : ''
    },
    cancelButtonVisible () {
      const hasStep = ['DELIVERY_PENDING','DELIVERY_SCHEDULED', 'DELIVERY_NOSHOW', 'PAYMENT_PENDING', 'PAYMENT_PENDING_PAYMENT'].includes(this.driverStep)
      const hasCognitoGroup = this.$store.getters['user/groups'].includes('RULE:CANCEL:DELIVERY')

      if (hasStep && hasCognitoGroup) return true

      return false
    },
    booking() {
      if (this.driver && this.driver.booking) {
        return this.driver.booking;
      }

      return null;
    },
    car() {
      if (this.booking) {
        return this.booking.car;
      }
      return null;
    }
  },
  watch: {
    async proofImage(val) {
      try {
        const transactions = Object.keys(val);
        const transactionID = transactions[0];
        const document = this.proofImage[transactionID];

        if (!document) throw new Error('Document not found');

        const currentTransaction = this.transactions.items.find(item => item.id === transactionID);
        const userEmail = this.$store.getters['user/attributes'].email;
        await this.$apollo
          .mutate({
            mutation: TRANSACTION_PAY,
            variables: {
              id: currentTransaction.id,
              driver: document.driver_id,
              invoice: currentTransaction.invoice,
              amount_paid: currentTransaction.amount,
              payment_method_token: currentTransaction.payment_method.token,
              internal_comments: `Comprovante enviado por ${userEmail}`,
              proof: document.id,
            },
          })
          .then(async () => {
            await this.$apollo.queries.transactions.refresh();
          });
        delete this.proofImage[transactionID];
      } catch (err) {
        this.$log.logError(err);
      }
    },
  },
  apollo: {
    transactions: {
      query: LIST_TRANSACTIONS,
      variables() {
        return {
          filters: {
            where: {
              driver: this.driver.id,
              booking: this.driver.booking.id,
              kind: ['PRE_PAYMENT', 'PRE_PAYMENT_RTO', 'PRE_PAYMENT_PROMOTIONAL', 'FIRST_PAYMENT'],
            },
          },
        };
      },
      update: data => data.transactions,
      skip() {
        return this.window_closed;
      },
    },
  },
  methods: {
    handleOpen() {
      this.window_closed = false;
    },
    async handleClose() {
      this.window_closed = true;
    },
    async $clickManualPay() {
      await this.$store.dispatch('driver/makeTransactionPaid', this.driver.id).then(async () => {
        await this.$store.dispatch('driver/signupStatus');
        await this.$parent.$refs.drivers_datatable.$_refresh();
        await this.$root.$emit('bv::hide::modal', 'driverSignupPayments');
      });
    },
    $_confirmCancelDelivery() {
      const configSwal = {
        type: 'warning',
        title: this.$i18n.t('modalVehicleDelivery.texts.cancelRental'),
        text: this.$i18n.t('modalVehicleDelivery.texts.toCancelDelivery'),
        showCancelButton: true,
        cancelButtonText: this.$i18n.t('modalVehicleDelivery.words.close'),
        showConfirmButton: true,
        confirmButtonText: this.$i18n.t('modalVehicleDelivery.words.confirm'),
        confirmButtonClass: 'driver-container-finish-booking-button',
        showLoaderOnConfirm: true,
        preConfirm: () => this.cancelDelivery(),
      };
      this.$swal(configSwal)
        .then(data => {
          if (data.dismiss) return;
          const configSwalSuccess = {
            type: 'success',
            title: this.$i18n.t('modalVehicleDelivery.texts.deliveryCanceled'),
            timer: 2000,
            text: '',
            showCancelButton: false,
            showConfirmButton: false,
          };
          this.$swal(configSwalSuccess).then(val => {
            this.$emit('finished');
          });
        })
        .catch(err => {
          this.$log.logError(err);
          const configSwalFail = {
            type: 'error',
            title: this.$i18n.t('modalVehicleDelivery.texts.cancelRental'),
            text: this.$i18n.t('modalVehicleDelivery.texts.couldNotCancelDelivery'),
          };
          this.$swal(configSwalFail);
        });
    },
    async cancelDelivery() {
      const booking = this.booking ? this.booking.id : null
      const car = this.car ? this.car.id : null
      const schedule = this.deliveryScheduleId ? this.deliveryScheduleId : null

      return this.$apollo
        .mutate({
          mutation: this.cancel_delivery_mutation,
          variables: {
            input: {
              driver: this.driverId,
              booking,
              car,
              schedule
            }
          },
        })
        .then(response => {
          const data = response.data.rentalCancel;

          let hasCar = false
          let hasSchedule = false

          if (!car && !data.car || car && data.car) {
            hasCar = true
          }

          if (!schedule && !data.schedule || schedule && data.schedule) {
            hasSchedule = true
          }

          if (!data.driver || !data.booking || !hasCar || !hasSchedule) {
            return Promise.reject(
              this.$i18n.t('modalVehicleDelivery.texts.toCancelDelivery')
            );
          }

          return true;
        })
        .catch(err => {
          this.$log.logError(err);
          return Promise.reject(
            this.$i18n.t('modalVehicleDelivery.texts.couldNotCancelDelivery')
          );
        });
    },
    $confirmManualPay(transaction) {
      if (!this.proofImage) {
        return this.$swal({
          type: 'error',
          text: 'Você precisa realizar o upload do comprovante',
          title: 'Ops...',
        });
      }
      const confirmationWindow = this.$swal.mixin({
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger mr-3',
        buttonsStyling: false,
      });
      confirmationWindow({
        title: 'Tem certeza?',
        text: 'Você irá CONFIRMAR esta cobrança',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
        input: 'text',
        inputValue: '',
        inputPlaceholder: 'Como o motorista pagou essa cobrança?',
        inputValidator: value => {
          return !value && 'Sorry! Você precisa digitar algum motivo!';
        },
        inputAttributes: {
          maxlength: 250,
          autocomplete: 'off',
        },
      }).then(async result => {
        if (!result || result.dismiss === 'cancel') return;
        const document = await this.$store.dispatch('driverDocument/uploadProofOfPayment', {
          driverId: this.driver.id,
          file: this.proofImage,
        });

        if (!document) {
          return this.$swal({
            type: 'error',
            title: 'Ocorreu um erro!',
            text: 'Não foi possível realizar o comprovante do pagamento',
            showConfirmButton: false,
          });
        }

        const userEmail = this.$store.getters['user/attributes'].email;
        this.$apollo
          .mutate({
            mutation: TRANSACTION_PAY,
            variables: {
              id: transaction.id,
              driver: this.driver.id,
              invoice: transaction.invoice,
              amount_paid: transaction.amount,
              payment_method_token: transaction.payment_method.token,
              internal_comments: `[${userEmail}] ${result.value}`,
              proof: document.body.id,
            },
          })
          .then(() => {
            this.$swal({
              type: 'success',
              title: 'Comprovante salvo com sucesso!',
              showConfirmButton: false,
              timer: 2500,
            }).then(async () => {
              await this.$apollo.queries.transactions.refresh();
            });
          })
          .catch(() => {
            this.$swal({
              type: 'error',
              title: 'Ocorreu um erro!',
              text: 'Verifique as informações novamente',
              showConfirmButton: false,
              timer: 2500,
            });
          });
      });
    },
    setPriceFormat(_value) {
      if (!_value) _value = 0

      return formatPrice(this.$t('payments.labels.currency'), _value);
    },
  },
};
</script>
