var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.status
    ? _c(
        "b-card-group",
        { staticClass: "mb-4" },
        _vm._l(_vm.status, function (item) {
          return _c("card-widget", {
            key: item.name,
            attrs: {
              id: item.id,
              to: item.to,
              title: item.title,
              icon: item.icon,
              value: item.total.toString(),
              active: _vm.statusWidgetIsActive(item.id),
            },
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }