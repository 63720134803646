<template>
  <section class="view-signup-drivers">
    <b-row class="pb-4">
      <b-col cols="12">
        <h2>{{ this.$route.meta.title || $t('drivers.titles.drivers') }}</h2>
      </b-col>
    </b-row>

    <status-counter
      v-if="!['DriversSignupDeliveryScheduled', 'DriversSignupWaitingList'].includes(this.$route.name)"
    />

    <custom-data-table
      ref="drivers_datatable"
      :fields="fields"
      :filters="filters"
      :sort="sort"
      :fixed-filters="fixed_filters"
      :query="signup_steps_query"
      query-node="driversSignupScore"
      @vuetable:row-clicked="$open"
    >
      <span slot="name" slot-scope="props">
        <span>{{ props.rowData.name || $t('drivers.texts.anonymous') }}</span>

        <span
          v-for="obj in usersAcessingDrivers.filter(u => u.driverId === props.rowData.id)"
          :key="obj.id"
          class="badge-user"
          :title="obj.email"
        >{{ obj.email.split('@')[0] }}</span>
      </span>
      <span slot="cpf" slot-scope="props">{{ props.rowData.cpf | format_document }}</span>
      <span slot="created_at" slot-scope="props">{{ props.rowData.created_at | format_from_now }}</span>
      <span
        slot="step_updated_at"
        slot-scope="props"
      >{{ props.rowData.step_updated_at | format_from_now }}</span>
      <span slot="booking.car_scheduled_at" slot-scope="props">
        <span
          v-if="props.rowData.booking && props.rowData.booking.car_scheduled_at"
        >{{ props.rowData.booking.car_scheduled_at | format_date('DD/MM/YYYY HH:mm') }}</span>
        <span v-else>{{ $t('drivers.texts.notScheduled') }}</span>
      </span>
      <span slot="step" slot-scope="props">
        <b-badge variant="default">{{ props.rowData.step | signup_step_status }}</b-badge>
      </span>
      <span slot="bgc_status" slot-scope="props">
        <b-badge :class="props.rowData.bgc_status">
          {{ $t(`drivers.texts.bgcStatus.${props.rowData.bgc_status}`) }}
        </b-badge>
      </span>

      <span slot="send_invite" slot-scope="props">
        <b-button
          variant="primary"
          size="sm"
          @click.prevent="$invite(props.rowData.id)"
        >{{ $t('drivers.buttons.inviteSend') }}</b-button>
      </span>
      <span slot="kovi_signup_score" slot-scope="props">{{ props.rowData.kovi_signup_score }}</span>
    </custom-data-table>
    <modal-driver-signup-payments :driver="current_driver" @finished="$_modalCallback" />
    <modal-driver-signup-schedule :driver="current_driver" @refresh="$_modalCallback" />
    <modal-driver-signup-rescheduling :driver="current_driver" @refresh="$_modalCallback" />
    <modal-driver-signup-delivery :driver="current_driver" @finished="$_modalCallback" />
    <modal-driver-signup-invite :driver="current_driver" />
  </section>
</template>

<script>
import CustomDataTable from '@components/CustomDataTable';
import StatusCounter from '@components/drivers/signup/status-counter';
import ModalDriverSignupPayments from '@components/drivers/signup/modals/payments';
import ModalDriverSignupSchedule from '@components/drivers/signup/modals/schedule';
import ModalDriverSignupDelivery from '@components/drivers/signup/modals/delivery';
import ModalDriverSignupInvite from '@components/drivers/signup/modals/invite-pending';
import ModalDriverSignupRescheduling from '@components/drivers/signup/modals/Rescheduling'
// driverPaymentInfo
import SIGNUP_DRIVERS_QUERY from '@graphql/signup/queries/listSignupScore.gql';

export default {
  name: 'DriverSignup',
  components: {
    StatusCounter,
    CustomDataTable,
    ModalDriverSignupInvite,
    ModalDriverSignupPayments,
    ModalDriverSignupSchedule,
    ModalDriverSignupDelivery,
    ModalDriverSignupRescheduling,
  },
  data() {
    return {
      usersAcessingDrivers: [

      ],
      current_driver: {
        id: null,
      },
      userCountry: this.$store.getters['user/country'],
      userRegion: this.$store.getters['user/region'],
      signup_steps_query: SIGNUP_DRIVERS_QUERY,
      fields: this.$route.meta.fields,
      filters: {
        query: {
          type: 'textfield',
          props: {
            label: this.$t('drivers.labels.searchDriver'),
            placeholder: this.$t('drivers.labels.writeFor'),
          },
          graphqlQuery(value) {
            return {
              $or: [
                {
                  name: {
                    $like: `%${value}%`,
                  },
                },
                {
                  cpf: {
                    $like: `%${value}%`,
                  },
                },
                {
                  bgc_status: {
                    $like: `%${value}%`,
                  },
                },
                {
                  email: {
                    $like: `%${value}%`,
                  },
                },
                {
                  phone_number: {
                    $like: `%${value}%`,
                  },
                },
              ],
            };
          },
        },
      },
      fixed_filters: {
        ...this.$route.meta.filters,
        ...{ step: this.$route.meta.step },
      },
      sort: this.$route.meta.sort || [['step_updated_at', 'DESC']],
    };
  },

    mounted() {
      this.$pubsub('driversList').subscribe(data => {
        const list = [...this.usersAcessingDrivers];
        for (const item of Array.from(data)) {
          if (
            item.action === 'enter' &&
            !list.some(
              u => u.driverId === item.driverId && u.email === item.email
            )
          ) {
            list.push({ driverId: item.driverId, email: item.email });
          } else if (item.action === 'exit') {
            const idx = list.findIndex(
              u => u.driverId === item.driverId && u.email === item.email
            );
            if (idx >= 0) list.splice(idx, 1);
          }
        }
        this.usersAcessingDrivers = [...list];
      });

    const setFilter = (filter, field = 'step') => {
      if (filter) {
        if (field !== 'utm') {
          this.fixed_filters[field] = filter;
        } else {
          this.setUtmFilter(filter);
        }
      } else {
        this.fixed_filters[field] = this.$route.meta[field];
      }
    };

    const docsPending = {
      driver_step: {
        type: 'select',
        cols: 2,
        value: null,
        props: {
          label: this.$t('drivers.labels.registerStatus'),
          options: [
            {
              value: null,
              text: this.$t('drivers.labels.all'),
            },
            {
              value: 'APPS_PENDING',
              text: this.$t('drivers.labels.appsPending'),
            },
            {
              value: 'DOCS_PENDING',
              text: this.$t('drivers.labels.docsPending'),
            },
            {
              value: 'DOCS_REJECTED',
              text: this.$t('drivers.labels.docsReject'),
            },
          ],
        },
        graphqlQuery(value) {
          setFilter(value);
        },
      },
    };

    const docsChecking = {
      driver_step: {
        type: 'select',
        cols: 2,
        /* Aqui define o valor default, mas não busca por ele. Só seta o default.
        Quem define o valor buscado está na linha /src/router/routes.js:232*/
        value: ['APPROVED', 'APPROVED_MANUALLY', 'MISSING_FINANCE'],
        props: {
          label: this.$t('drivers.labels.bgcStatus'),
          options: [
            {
              value: ['APPROVED', 'APPROVED_MANUALLY', 'MISSING_FINANCE'],
              text: this.$t('drivers.texts.approved'),
            },
            {
              value: ['FINANCE_FAILED'],
              text: this.$t('drivers.texts.approvedFailedSerasa'),
            },
            {
              value: [
                'PENDING', 'NATIONAL_ID_IS_INVALID', 'FINANCE_PENDING', 'DRIVER_LICENSE_PENDING', 'DRIVER_LICENSE_FAILED',
                'MISSING_TRANSIT', 'MISSING_RISK_AREA', 'MISSING_CHECK_EMAIL', 'MISSING_CHECK_APPS', 'MISSING_BASIC_INFO',
                'MISSING_CRIMINAL', 'RISK_AREA_FAILED', 'CHECK_EMAIL_FAILED', 'CHECK_APPS_FAILED', 'BASIC_INFO_FAILED', 'CRIMINAL_FAILED'
              ],
              text: this.$t('drivers.texts.pending'),
            },
            {
              value: ['CHECKING'],
              text: this.$t('drivers.texts.checking'),
            },
            {
              value: { $not: [
                'APPROVED', 'APPROVED_MANUALLY', 'MISSING_FINANCE', 'FINANCE_FAILED', 'CHECKING',
                'PENDING', 'NATIONAL_ID_IS_INVALID', 'FINANCE_PENDING', 'DRIVER_LICENSE_PENDING', 'DRIVER_LICENSE_FAILED',
                'MISSING_TRANSIT', 'MISSING_RISK_AREA', 'MISSING_CHECK_EMAIL', 'MISSING_CHECK_APPS', 'MISSING_BASIC_INFO',
                'MISSING_CRIMINAL', 'RISK_AREA_FAILED', 'CHECK_EMAIL_FAILED', 'CHECK_APPS_FAILED', 'BASIC_INFO_FAILED', 'CRIMINAL_FAILED'
              ] },
              text: this.$t('drivers.texts.disapproved'),
            },
            {
              value: null,
              text: this.$t('drivers.texts.all'),
            },
          ],
        },
        graphqlQuery(value) {
          setFilter(value, 'bgc_status');
        },
      },
    };

    const invite = {
      driver_step: {
        type: 'select',
        cols: 2,
        value: null,
        props: {
          label: this.$t('drivers.labels.registerStatus'),
          options: [
            {
              value: null,
              text: this.$t('drivers.labels.all'),
            },
            {
              value: 'INVITE_PENDING',
              text: this.$t('drivers.labels.invitePending'),
            },
            {
              value: 'INVITE_EXPIRED',
              text: this.$t('drivers.labels.inviteExpired'),
            },
            {
              value: 'INVITE_SENT',
              text: this.$t('drivers.labels.inviteSend'),
            },
          ],
        },
        graphqlQuery(value) {
          setFilter(value);
        },
      },
      utm: {
        type: 'select',
        cols: 2,
        value: null,
        props: {
          label: this.$t('drivers.labels.utmPlanCampaign'),
          options: [
            {
              value: null,
              text: this.$t('drivers.labels.all'),
            },
            {
              value: 'kovi-max',
              text: 'kovi-max',
            },
            {
              value: 'kovi-dsj',
              text: 'kovi-dsj',
            },
          ],
        },
        graphqlQuery(value) {
          setFilter({ utm_plan: value }, 'utm');
        },
      },
    };

    const payments = {
      driver_step: {
        type: 'select',
        cols: 3,
        value: null,
        props: {
          label: this.$t('drivers.labels.registerStatus'),
          options: [
            {
              value: null,
              text: this.$t('drivers.labels.all'),
            },
            {
              value: 'PAYMENT_PENDING_PAYMENT',
              text: this.$t('drivers.labels.paymentPendingPayment'),
            },
            {
              value: 'PAYMENT_PENDING',
              text: this.$t('drivers.labels.paymentPending'),
            },
            {
              value: 'PLAN_PENDING',
              text: this.$t('drivers.labels.planPending'),
            },
          ],
        },
        graphqlQuery(value) {
          setFilter(value);
        },
      },
    };

    const delivery = {
      driver_step: {
        type: 'select',
        cols: 2,
        value: null,
        props: {
          label: this.$t('drivers.labels.registerStatus'),
          options: [
            {
              value: null,
              text: this.$t('drivers.labels.all'),
            },
            {
              value: 'DELIVERY_PENDING',
              text: this.$t('drivers.labels.deliveryPending'),
            },
            {
              value: 'DELIVERY_SCHEDULED',
              text: this.$t('drivers.labels.deliveryScheduled'),
            },
            {
              value: 'DELIVERY_NOSHOW',
              text: this.$t('drivers.labels.deliveryNoShow'),
            },
          ],
        },
        graphqlQuery(value) {
          setFilter(value);
        },
      },
    };

    switch (this.$route.name) {
      case 'DriversSignupDocsChecking':
        this.filters = {
          ...this.filters,
          ...docsChecking,
        };
        break;
      case 'DriversSignupDocsPending':
        this.filters = {
          ...this.filters,
          ...docsPending,
        };
        break;
      case 'DriversSignupInvite':
        this.filters = {
          ...this.filters,
          ...invite,
        };
        break;
      case 'DriversSignupPaymentsPending':
        this.filters = {
          ...this.filters,
          ...payments,
        };
        break;
      case 'DriversSignupDeliverySchedules':
        this.filters = {
          ...this.filters,
          ...delivery,
        };
        break;
      default:
        this.filters = {
          ...this.filters,
        };
    }
  },
  methods: {
    setUtmFilter (value) {
      const utmPlan = value.utm_plan
      let currentFixedFilters = this.fixed_filters

      if (!utmPlan) {
        delete currentFixedFilters.utm
      } else {
        currentFixedFilters = {
          ...currentFixedFilters,
          utm: value
        }
      }

      this.fixed_filters = {
        ...currentFixedFilters
      }
    },
    $invite(driver_id) {
      this.$store.dispatch('driver/sendInvite', driver_id).then(() => {
        this.$store.dispatch('driver/signupStatus');
        this.$refs.drivers_datatable.$_refresh();
      });
    },
    $_modalCallback() {
      this.$root.$emit('bv::hide::modal', 'driverSignupSchedule');
      this.$root.$emit('bv::hide::modal', 'driverSignupRescheduling');
      this.$root.$emit('bv::hide::modal', 'driverSignupDelivery');
      this.$root.$emit('bv::hide::modal', 'driverSignupPayments');

      Promise.resolve(this.$store.dispatch('driver/signupStatus')).then(() => this.$refs.drivers_datatable.$_refresh());
    },
    $open(_item = null) {
      const driver = _item.data;
      this.current_driver = { id: null };
      this.current_driver = driver;

      switch (driver.step) {
        case 'APPS_PENDING':
        case 'DOCS_PENDING':
        case 'DOCS_REJECTED':
        case 'DOCS_CHECKING':
        case 'BGC_REJECTED':
          this.$router.push({
            path: `/drivers/signup/docs/detail/${driver.id}`,
            query: {
              redir: this.$route.path,
            },
          });
          break;
        case 'PAYMENT_PENDING':
        case 'PAYMENT_PENDING_PAYMENT':
          this.$root.$emit('bv::show::modal', 'driverSignupPayments');
          break;
        case 'DELIVERY_PENDING':
          this.$root.$emit('bv::show::modal', 'driverSignupSchedule');
          break;
        case 'DELIVERY_SCHEDULED':
        case 'DELIVERY_NOSHOW':
          this.$root.$emit('bv::show::modal', 'driverSignupDelivery');
          break;
        case 'DOCS_APPROVED':
          this.$root.$emit('bv::show::modal', 'driverSignupInvite');
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.badge-user
  display: inline-block
  padding: 0.3em 0.6em
  margin: 0.5em 0.5rem 0px
  font-size: 60%
  text-transform: uppercase
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)
  background-color: #ffc107
  border-radius: 0.25rem

.badge
  &.APPROVED
    background-color: #4dbd74
  &.CHECKING,
  &.PENDING,
  &.DRIVER_LICENSE_PENDING,
  &.WAITING_FINANCIAL,
  &.OUTSIDE_PRACTICE_AREA,
  &.PROBLEM_ADDRESS,
  &.PROBLEM_NATIONAL_ID,
  &.DRIVER_LICENSE_EXPIRING
    background-color: #ffc107
  &.HAS_LEGAL_AGE,
  &.DRIVER_LICENSE_IMPEDIMENTS,
  &.DISAPPROVED
    background-color: #C21F39
</style>
