var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "view-signup-drivers" },
    [
      _c(
        "b-row",
        { staticClass: "pb-4" },
        [
          _c("b-col", { attrs: { cols: "12" } }, [
            _c("h2", [
              _vm._v(
                _vm._s(
                  this.$route.meta.title || _vm.$t("drivers.titles.drivers")
                )
              ),
            ]),
          ]),
        ],
        1
      ),
      !["DriversSignupDeliveryScheduled", "DriversSignupWaitingList"].includes(
        this.$route.name
      )
        ? _c("status-counter")
        : _vm._e(),
      _c("custom-data-table", {
        ref: "drivers_datatable",
        attrs: {
          fields: _vm.fields,
          filters: _vm.filters,
          sort: _vm.sort,
          "fixed-filters": _vm.fixed_filters,
          query: _vm.signup_steps_query,
          "query-node": "driversSignupScore",
        },
        on: { "vuetable:row-clicked": _vm.$open },
        scopedSlots: _vm._u([
          {
            key: "name",
            fn: function (props) {
              return _c(
                "span",
                {},
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        props.rowData.name || _vm.$t("drivers.texts.anonymous")
                      )
                    ),
                  ]),
                  _vm._l(
                    _vm.usersAcessingDrivers.filter(
                      (u) => u.driverId === props.rowData.id
                    ),
                    function (obj) {
                      return _c(
                        "span",
                        {
                          key: obj.id,
                          staticClass: "badge-user",
                          attrs: { title: obj.email },
                        },
                        [_vm._v(_vm._s(obj.email.split("@")[0]))]
                      )
                    }
                  ),
                ],
                2
              )
            },
          },
          {
            key: "cpf",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(_vm._s(_vm._f("format_document")(props.rowData.cpf))),
              ])
            },
          },
          {
            key: "created_at",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(
                  _vm._s(_vm._f("format_from_now")(props.rowData.created_at))
                ),
              ])
            },
          },
          {
            key: "step_updated_at",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(
                  _vm._s(
                    _vm._f("format_from_now")(props.rowData.step_updated_at)
                  )
                ),
              ])
            },
          },
          {
            key: "booking.car_scheduled_at",
            fn: function (props) {
              return _c("span", {}, [
                props.rowData.booking && props.rowData.booking.car_scheduled_at
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("format_date")(
                            props.rowData.booking.car_scheduled_at,
                            "DD/MM/YYYY HH:mm"
                          )
                        )
                      ),
                    ])
                  : _c("span", [
                      _vm._v(_vm._s(_vm.$t("drivers.texts.notScheduled"))),
                    ]),
              ])
            },
          },
          {
            key: "step",
            fn: function (props) {
              return _c(
                "span",
                {},
                [
                  _c("b-badge", { attrs: { variant: "default" } }, [
                    _vm._v(
                      _vm._s(_vm._f("signup_step_status")(props.rowData.step))
                    ),
                  ]),
                ],
                1
              )
            },
          },
          {
            key: "bgc_status",
            fn: function (props) {
              return _c(
                "span",
                {},
                [
                  _c("b-badge", { class: props.rowData.bgc_status }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            `drivers.texts.bgcStatus.${props.rowData.bgc_status}`
                          )
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              )
            },
          },
          {
            key: "send_invite",
            fn: function (props) {
              return _c(
                "span",
                {},
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary", size: "sm" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.$invite(props.rowData.id)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("drivers.buttons.inviteSend")))]
                  ),
                ],
                1
              )
            },
          },
          {
            key: "kovi_signup_score",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(_vm._s(props.rowData.kovi_signup_score)),
              ])
            },
          },
        ]),
      }),
      _c("modal-driver-signup-payments", {
        attrs: { driver: _vm.current_driver },
        on: { finished: _vm.$_modalCallback },
      }),
      _c("modal-driver-signup-schedule", {
        attrs: { driver: _vm.current_driver },
        on: { refresh: _vm.$_modalCallback },
      }),
      _c("modal-driver-signup-rescheduling", {
        attrs: { driver: _vm.current_driver },
        on: { refresh: _vm.$_modalCallback },
      }),
      _c("modal-driver-signup-delivery", {
        attrs: { driver: _vm.current_driver },
        on: { finished: _vm.$_modalCallback },
      }),
      _c("modal-driver-signup-invite", {
        attrs: { driver: _vm.current_driver },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }