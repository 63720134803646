<script>
import CardWidget from '@components/shared/card-widget';

export default {
  name: 'DriverStatus',
  components: {
    CardWidget,
  },
  props: {
    filter: {
      type: String,
    },
  },
  computed: {
    status() {
      return this.$store.getters['driver/signupStatus'];
    },
  },
  mounted() {
    this.$store.dispatch('driver/signupStatus');
  },
  methods: {
    goTo(link) {
      this.$router.push({ path: link });
    },
    statusWidgetIsActive(routerName) {
      return this.$route.name === routerName;
    },
  },
};
</script>

<template>
  <b-card-group
    v-if="status"
    class="mb-4"
  >
    <card-widget
      v-for="item in status"
      :id="item.id"
      :key="item.name"
      :to="item.to"
      :title="item.title"
      :icon="item.icon"
      :value="item.total.toString()"
      :active="statusWidgetIsActive(item.id)"
    />
  </b-card-group>
</template>
