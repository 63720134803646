var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "driverSignupPayments",
        "no-enforce-focus": "",
        lazy: "",
        "hide-footer": "",
        size: "lg",
        title: _vm.$t("payments.deliverScheduled.modalTitle"),
      },
      on: { show: _vm.handleOpen, hide: _vm.handleClose },
    },
    [
      _c("driver-header", { attrs: { id: _vm.driver.id, modal: true } }),
      !this.$apollo.queries.transactions.loading
        ? _c(
            "b-card",
            [
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { md: "6" } }, [
                    _vm.driver.booking
                      ? _c("p", { staticClass: "mb-2" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("payments.deliverScheduled.planTitle")
                              ) +
                              ": "
                          ),
                          _c("strong", [
                            _vm._v(_vm._s(_vm.driver.booking.plan.name)),
                          ]),
                        ])
                      : _c("p", { staticClass: "mb-2" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("payments.deliverScheduled.planTitle")
                              ) +
                              ": "
                          ),
                          _c("strong", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "payments.deliverScheduled.planNotSelected"
                                )
                              ),
                            },
                          }),
                        ]),
                    _vm.driver.booking && _vm.driver.booking.driver_scheduled_at
                      ? _c("p", { staticClass: "mb-2" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "payments.deliverScheduled.planScheduledAt"
                                )
                              ) +
                              ": "
                          ),
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm._f("format_date")(
                                  _vm.driver.booking.driver_scheduled_at
                                )
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                  _c("b-col", { attrs: { md: "6" } }, [
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("payments.deliverScheduled.planLastUpdate")
                          ) +
                          ": "
                      ),
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm._f("format_from_now")(_vm.driver.updated_at)
                          )
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _c("hr"),
              _c(
                "b-row",
                _vm._l(_vm.transactions.items, function (transaction) {
                  return _c(
                    "b-col",
                    { key: transaction.id, attrs: { md: "6" } },
                    [
                      _c("h5", [
                        _vm._v(
                          _vm._s(
                            [
                              "PRE_PAYMENT",
                              "PRE_PAYMENT_RTO",
                              "PRE_PAYMENT_PROMOTIONAL",
                            ].includes(transaction.kind)
                              ? _vm.$t(
                                  "payments.deliverScheduled.transactionKindPre"
                                )
                              : _vm.$t(
                                  "payments.deliverScheduled.transactionKindWeek"
                                )
                          )
                        ),
                      ]),
                      _c("p", { staticClass: "mb-2" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "payments.deliverScheduled.transactionType"
                              )
                            ) +
                            ": "
                        ),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm._f("filterPaymentMethodType")(
                                transaction.payment_method.type
                              )
                            )
                          ),
                        ]),
                      ]),
                      _c("p", { staticClass: "mb-2" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("payments.labels.expireDate")) +
                            ": "
                        ),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm._f("format_date")(transaction.overdue_limit)
                            )
                          ),
                        ]),
                      ]),
                      _c("p", { staticClass: "mb-4" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "payments.deliverScheduled.transactionAmount"
                              )
                            ) +
                            ": "
                        ),
                        _c("strong", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.setPriceFormat(transaction.amount)
                            ),
                          },
                        }),
                      ]),
                      transaction.status === "PAID"
                        ? _c(
                            "p",
                            { staticClass: "mb-2" },
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip",
                                    },
                                  ],
                                  attrs: {
                                    variant: "success",
                                    disabled: "",
                                    title: _vm.$t(
                                      "payments.deliverScheduled.transactionStatusPaidTitle"
                                    ),
                                  },
                                },
                                [
                                  _c("i", { staticClass: "fa fa-check" }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "payments.deliverScheduled.transactionStatusPaidLabel"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _c("div", [
                            transaction.status === "PENDING" &&
                            transaction.manual_confirmation
                              ? _c("div", [
                                  _c(
                                    "p",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip",
                                            },
                                          ],
                                          attrs: {
                                            variant: "warning",
                                            href: `${_vm.invoiceURL}${transaction.id}`,
                                            target: "_blank",
                                            title: _vm.$t(
                                              "payments.deliverScheduled.transactionStatusPendingTitle"
                                            ),
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-external-link",
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "payments.deliverScheduled.transactionStatusPendingLabel"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "p",
                                    { staticClass: "mb-2" },
                                    [
                                      _c("upload-image", {
                                        attrs: {
                                          value: transaction.proof.url,
                                          label: _vm.$t(
                                            "payments.deliverScheduled.proofImageLabel"
                                          ),
                                          "file-upload-name": "data",
                                          "file-upload-endpoint": "/docs",
                                          "auto-upload": "",
                                          "file-upload-props": {
                                            driverId: _vm.driver.id,
                                            documentType: "proof_of_payment",
                                          },
                                          "data-test":
                                            "modal-transaction__btn-upload-proof",
                                        },
                                        model: {
                                          value: _vm.proofImage[transaction.id],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.proofImage,
                                              transaction.id,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "proofImage[transaction.id]",
                                        },
                                      }),
                                      !_vm.proofImage[transaction.id]
                                        ? _c("modal-show-image", {
                                            staticClass: "mb-2",
                                            attrs: {
                                              url: transaction.proof.url,
                                              title: _vm.$t(
                                                "payments.deliverScheduled.proofImageModal"
                                              ),
                                              display: transaction.proof
                                                ? 1
                                                : 0,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ])
                              : _c("div", [
                                  _c(
                                    "p",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip",
                                            },
                                          ],
                                          attrs: {
                                            variant: "danger",
                                            href: `${_vm.invoiceURL}${transaction.id}`,
                                            target: "_blank",
                                            title: _vm.$t(
                                              "payments.deliverScheduled.proofImageButtonTitle"
                                            ),
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-external-link",
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "payments.deliverScheduled.proofImageButtonLabel"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "p",
                                    { staticClass: "mt-4 ml-2 mr-2" },
                                    [
                                      _c("upload-image", {
                                        attrs: {
                                          label: _vm.$t(
                                            "payments.deliverScheduled.proofImageLabel"
                                          ),
                                          "help-text": _vm.$t(
                                            "payments.deliverScheduled.proofImageUploadText"
                                          ),
                                          "file-upload-name": "data",
                                          "file-upload-endpoint": "/docs",
                                          "auto-upload": "",
                                          "file-upload-props": {
                                            driverId: _vm.driver.id,
                                            documentType: "proof_of_payment",
                                          },
                                          "data-test":
                                            "modal-transaction__btn-upload-proof",
                                        },
                                        model: {
                                          value: _vm.proofImage[transaction.id],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.proofImage,
                                              transaction.id,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "proofImage[transaction.id]",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                          ]),
                    ]
                  )
                }),
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "text-center", attrs: { md: "12" } },
                    [
                      _c("hr"),
                      _vm.showConfirmPaymentButton
                        ? _c(
                            "b-button",
                            {
                              attrs: { variant: "success" },
                              on: {
                                click: function ($event) {
                                  return _vm.$clickManualPay()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "payments.deliverScheduled.confirmPayment"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "b-button",
                        {
                          staticClass: "font-weight-bold",
                          attrs: {
                            id: "driver-view-cancel-booking",
                            variant: "danger",
                            disabled: !_vm.cancelButtonVisible,
                          },
                          on: { click: _vm.$_confirmCancelDelivery },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$i18n.t(
                                  "modalVehicleDelivery.button.cancelDelivery"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("b-card", [
            _c("p", { staticClass: "text-center mb-0 p-5" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("payments.deliverScheduled.loadingPayment")) +
                  ".... "
              ),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }