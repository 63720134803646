var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "b-col",
        {
          staticClass: "mt-sm-3 mt-md-0 card card-reset",
          staticStyle: { padding: "15px" },
          attrs: { md: "12" },
        },
        [
          _c("select-places", {
            attrs: {
              type: { delivery: true },
              "place-title": _vm.$i18n.t("drivers.labels.placesDelivery"),
              "place-text": _vm.$i18n.t("drivers.labels.selectPlaces"),
            },
            on: {
              eventPlaces: function ($event) {
                return _vm.onEventPlaces($event)
              },
            },
          }),
        ],
        1
      ),
      _vm.placeSelected
        ? _c(
            "b-col",
            {
              staticClass: "mt-sm-3 mt-md-0 card card-reset",
              staticStyle: { padding: "15px" },
              attrs: { md: "12" },
            },
            [
              _c("p", [
                _vm._v(_vm._s(_vm.$i18n.t("drivers.labels.reasonSchedule"))),
              ]),
              _c("b-form-textarea", {
                attrs: {
                  id: "textAreaReasonChedule",
                  rows: "3",
                  placeholder: _vm.$i18n.t("drivers.labels.typeHere"),
                  "max-rows": "6",
                },
                model: {
                  value: _vm.reasonText,
                  callback: function ($$v) {
                    _vm.reasonText = $$v
                  },
                  expression: "reasonText",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.placeSelected
        ? _c(
            "b-card",
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("span", { staticClass: "d-block w-100 text-center" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$i18n.t(
                              "modalWaitingDelivery.texts.selectEndDeliveryDate"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "d-flex justify-content-center mt-3" },
                [
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c(
                        "datepicker",
                        _vm._b(
                          {
                            attrs: { lang: _vm.getLanguage },
                            model: {
                              value: _vm.schedule_date,
                              callback: function ($$v) {
                                _vm.schedule_date = $$v
                              },
                              expression: "schedule_date",
                            },
                          },
                          "datepicker",
                          _vm.date_picker_props,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            id: "button_update_booking",
                            disabled: !_vm.is_valid_schedule_date,
                            variant: "success",
                          },
                          on: { click: _vm.$_confirmSchedule },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$i18n.t(
                                  "modalWaitingDelivery.texts.scheduleDelivery"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-col",
        { staticClass: "card card-reset", staticStyle: { padding: "15px" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "text-center" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "font-weight-bold",
                      attrs: {
                        id: "driver-view-cancel-booking",
                        variant: "danger",
                        disabled: !_vm.cancelButtonVisible,
                      },
                      on: { click: _vm.$_confirmCancelDelivery },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$i18n.t(
                              "modalVehicleDelivery.button.cancelDelivery"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }