var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "driverSignupInvite",
        "no-enforce-focus": "",
        lazy: "",
        "hide-footer": "",
        size: "lg",
        title: "Informações do Motorista",
      },
    },
    [_c("driver-header", { attrs: { id: _vm.driver.id, modal: true } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }