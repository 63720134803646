<template>
  <b-modal id="driverSignupSchedule" ref="driverPendingBookingInfo" lazy hide-footer size="lg" :title="$i18n.t('modalWaitingDelivery.texts.deliverySchedule')" @show="handleOpen" @hide="handleClose">
    <driver-header :modal="true" :id="driver.id" />

    <schedule-module :driver="driver"  @refreshDataTable="onRefreshDataTable" @finished="$_modalCallback"></schedule-module>
  </b-modal>
</template>

<script>
import DriverHeader from '@components/drivers/header'
import ScheduleModule from '@components/drivers/signup/ScheduleModule'

export default {
  name: 'ModalDriverSignupPayments',

  components: {
    DriverHeader,
    ScheduleModule,
  },

  props: {
    driver: {
      type: Object,
      required: true
    },
  },

  methods: {
    handleOpen() {
      this.window_closed = false
    },

    handleClose() {
      this.window_closed = true
    },

    onRefreshDataTable () {
      this.$parent.$refs.drivers_datatable.$_refresh();
    },
    $_modalCallback() {
      this.$emit('refresh');
    }
  }
};
</script>

<style lang="scss"scoped>
</style>
