<template>
  <b-modal
    id="driverSignupInvite"
    no-enforce-focus
    lazy
    hide-footer
    size="lg"
    title="Informações do Motorista"
  >
    <driver-header :id="driver.id" :modal="true" />
  </b-modal>
</template>

<style scoped></style>

<script>
import DriverHeader from '@components/drivers/header';

export default {
  name: 'ModalDriverSignupInvite',
  components: {
    DriverHeader,
  },
  props: {
    driver: {
      type: Object,
      required: true,
    },
  },
};
</script>
